@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: sans-serif, "Roboto", "Poppins", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", -apple-system, BlinkMacSystemFont, "Segoe UI";
  letter-spacing: "10px";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  box-sizing: border-box;
}

/* somehow scrollbar vanishes - why */
::-webkit-scrollbar {
  height: 10px;
  width: 0px; /* Adjust this value as needed */
}

::-webkit-scrollbar-thumb {
  /* Adjust this value to match the width of the track */
}
