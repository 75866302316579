.skewed-background {
  height: 300px;
  width: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
}
/* Style for the SVG element */
.skewed-background svg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
/* Style for the path element */
.skewed-background path {
  fill: #f7f7f7;
}
